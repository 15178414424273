import { FaLinkedin, FaEnvelope, FaImages } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="bg-white dark:bg-gray-800 shadow-sm mt-8">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Created by Hornet's Nest IO
          </p>
          <div className="flex space-x-4">
            <a
              href="mailto:jody@hornetsnest.io"
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              title="Email"
            >
              <FaEnvelope className="h-5 w-5" />
            </a>
            <a
              href="https://www.hornetsnest.io"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              title="Hornet's Nest IO"
            >
              <img 
                src="https://www.hornetsnest.io/favicon.ico" 
                alt="Hornet's Nest IO"
                className="h-5 w-5"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/jodyroberts"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              title="LinkedIn"
            >
              <FaLinkedin className="h-5 w-5" />
            </a>
            <a
              href="https://www.jrobnc.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              title="Portfolio"
            >
              <FaImages className="h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}