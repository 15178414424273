import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import Home from './pages/Home';
import Summary from './pages/Summary';
import Activities from './pages/Activities';
import FindActivities from './pages/FindActivities';
import Settings from './pages/Settings';
import Login from './pages/Login';
import ActivityForm from './pages/ActivityForm';
import ProtectedRoute from './components/ProtectedRoute';

export default function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-8 h-8 border-2 border-primary-600 rounded-full animate-spin border-t-transparent"></div>
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/login"
        element={
          user ? <Navigate to="/summary" replace /> : (
            <Login />
          )
        }
      />
      
      <Route path="/" element={<Home />} />
      
      <Route path="/summary" element={<Summary />} />
      
      <Route
        path="/activities"
        element={
          <ProtectedRoute>
            <Activities />
          </ProtectedRoute>
        }
      />
      
      <Route
        path="/activity/new"
        element={
          <ProtectedRoute>
            <ActivityForm />
          </ProtectedRoute>
        }
      />
      
      <Route
        path="/activity/:id"
        element={
          <ProtectedRoute>
            <ActivityForm />
          </ProtectedRoute>
        }
      />
      
      <Route
        path="/find-activities"
        element={
          <ProtectedRoute>
            <FindActivities />
          </ProtectedRoute>
        }
      />
      
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}