import { useState, useEffect } from 'react';

export interface ActivityType {
  id: string;
  name: string;
}

const defaultActivityTypes: ActivityType[] = [
  { id: '1', name: 'Indoor Play' },
  { id: '2', name: 'Outdoor Adventure' },
  { id: '3', name: 'Educational' },
  { id: '4', name: 'Arts & Crafts' },
  { id: '5', name: 'Sports' },
  { id: '6', name: 'Music & Dance' }
];

export function useActivityTypes() {
  const [activityTypes, setActivityTypes] = useState<ActivityType[]>(() => {
    const saved = localStorage.getItem('activityTypes');
    return saved ? JSON.parse(saved) : defaultActivityTypes;
  });

  useEffect(() => {
    localStorage.setItem('activityTypes', JSON.stringify(activityTypes));
  }, [activityTypes]);

  const addActivityType = (name: string) => {
    const newType: ActivityType = {
      id: Math.random().toString(36).substring(2),
      name: name.trim()
    };
    setActivityTypes(prev => [...prev, newType]);
  };

  const removeActivityType = (id: string) => {
    setActivityTypes(prev => prev.filter(type => type.id !== id));
  };

  return { activityTypes, addActivityType, removeActivityType };
}