import { useState } from 'react';
import useActivities from '../hooks/useActivities';
import Layout from '../components/Layout';
import ActivityList from '../components/ActivityList';
import ActivityBarChart from '../components/ActivityBarChart';
import { Activity } from '../types/activity';

interface ActivityStats {
  totalDuration: number;
  averageDuration: number;
  totalActivities: number;
  byChild: Record<string, { count: number; duration: number }>;
}

export default function Summary() {
  const { activities, loading } = useActivities();
  const [selectedChild, setSelectedChild] = useState<string>('all');
  const [timeRange, setTimeRange] = useState<'week' | 'month' | 'year'>('month');

  const calculateStats = (activities: Activity[]): ActivityStats => {
    const filteredActivities = activities.filter(activity => {
      const activityDate = new Date(activity.date);
      const now = new Date();
      now.setHours(23, 59, 59, 999);
      
      if (timeRange === 'week') {
        const weekAgo = new Date(now);
        weekAgo.setDate(now.getDate() - 7);
        weekAgo.setHours(0, 0, 0, 0);
        return activityDate >= weekAgo && activityDate <= now;
      } else if (timeRange === 'month') {
        const monthAgo = new Date(now);
        monthAgo.setMonth(now.getMonth() - 1);
        monthAgo.setHours(0, 0, 0, 0);
        return activityDate >= monthAgo && activityDate <= now;
      } else {
        const yearAgo = new Date(now);
        yearAgo.setFullYear(now.getFullYear() - 1);
        yearAgo.setHours(0, 0, 0, 0);
        return activityDate >= yearAgo && activityDate <= now;
      }
    });

    const stats: ActivityStats = {
      totalDuration: 0,
      averageDuration: 0,
      totalActivities: filteredActivities.length,
      byChild: {}
    };

    filteredActivities.forEach(activity => {
      stats.totalDuration += activity.duration;
      
      activity.children.forEach(child => {
        if (!stats.byChild[child]) {
          stats.byChild[child] = { count: 0, duration: 0 };
        }
        stats.byChild[child].count += 1;
        stats.byChild[child].duration += activity.duration;
      });
    });

    stats.averageDuration = stats.totalActivities > 0 
      ? stats.totalDuration / stats.totalActivities 
      : 0;

    return stats;
  };

  if (loading) {
    return (
      <Layout>
        <div className="flex items-center justify-center min-h-[50vh]">
          <div className="w-8 h-8 border-2 border-primary-600 rounded-full animate-spin border-t-transparent"></div>
        </div>
      </Layout>
    );
  }

  const stats = calculateStats(activities);
  const displayStats = selectedChild === 'all' 
    ? stats 
    : {
        ...stats,
        totalActivities: stats.byChild[selectedChild]?.count || 0,
        totalDuration: stats.byChild[selectedChild]?.duration || 0,
        averageDuration: stats.byChild[selectedChild]
          ? stats.byChild[selectedChild].duration / stats.byChild[selectedChild].count
          : 0
      };

  const validChildren = Object.keys(stats.byChild).filter(child => 
    child === 'Dax' || child === 'Hazel'
  );

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="space-y-6">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-between items-center mb-6">
                <div className="flex space-x-4">
                  <select
                    value={selectedChild}
                    onChange={(e) => setSelectedChild(e.target.value)}
                    className="rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white text-sm"
                  >
                    <option value="all">All Children</option>
                    {validChildren.map(child => (
                      <option key={child} value={child}>{child}</option>
                    ))}
                  </select>
                  <select
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value as 'week' | 'month' | 'year')}
                    className="rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white text-sm"
                  >
                    <option value="week">Last Week</option>
                    <option value="month">Last Month</option>
                    <option value="year">Last Year</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                <div className="bg-gray-50 dark:bg-gray-700 overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                      Total Activities
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                      {displayStats.totalActivities}
                    </dd>
                  </div>
                </div>

                <div className="bg-gray-50 dark:bg-gray-700 overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                      Total Duration
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                      {Math.round(displayStats.totalDuration)} min
                    </dd>
                  </div>
                </div>

                <div className="bg-gray-50 dark:bg-gray-700 overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                      Average Duration
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                      {Math.round(displayStats.averageDuration)} min
                    </dd>
                  </div>
                </div>
              </div>

              {selectedChild === 'all' && validChildren.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Activity by Child
                  </h3>
                  <div className="space-y-4">
                    {validChildren.map((child) => (
                      <div key={child} className="flex justify-between items-center">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          {child}
                        </span>
                        <div className="text-sm text-gray-900 dark:text-white">
                          {stats.byChild[child].count} activities ({Math.round(stats.byChild[child].duration)} min)
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="mt-8">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Activity Distribution
                </h3>
                <ActivityBarChart
                  activities={activities}
                  selectedChild={selectedChild}
                  timeRange={timeRange}
                />
              </div>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                Recent Activities
              </h3>
              <ActivityList 
                activities={activities.slice(0, 5)}
                showDate={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}