import { useState, useEffect } from 'react';
import { getUserActivities, saveActivity } from '../services/firebase';
import { Activity } from '../types/activity';
import { sampleActivities } from '../data/sampleActivities';
import { useAuth } from './useAuth';

export default function useActivities() {
  const { user, isGuest } = useAuth();
  const [activities, setActivities] = useState<Activity[]>(() => {
    // Try to load activities from localStorage for guest mode
    if (isGuest) {
      const savedActivities = localStorage.getItem('guestActivities');
      if (!savedActivities || 
          savedActivities.includes('Emma') || 
          savedActivities.includes('Jack') || 
          savedActivities.includes('Sophie')) {
        localStorage.setItem('guestActivities', JSON.stringify(sampleActivities));
        return sampleActivities;
      }
      return JSON.parse(savedActivities);
    }
    return [];
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Save activities to localStorage when in guest mode
  useEffect(() => {
    if (isGuest) {
      localStorage.setItem('guestActivities', JSON.stringify(activities));
    }
  }, [activities, isGuest]);

  // Load activities from Firestore for authenticated users
  useEffect(() => {
    const loadActivities = async () => {
      if (!isGuest && user) {
        try {
          const userActivities = await getUserActivities(user.uid);
          setActivities(userActivities.length > 0 ? userActivities : sampleActivities);
          setError(null);
        } catch (err) {
          console.error('Error loading activities:', err);
          setActivities(sampleActivities);
          setError('Unable to load activities. Using sample data instead.');
        }
      }
      setLoading(false);
    };

    loadActivities();
  }, [user, isGuest]);

  const addActivity = async (activityData: Omit<Activity, 'id'>) => {
    try {
      if (isGuest) {
        const newActivity = {
          ...activityData,
          id: Math.random().toString(36).substring(2)
        };
        setActivities(prev => [newActivity, ...prev]);
        return newActivity.id;
      } else if (user) {
        const activityId = await saveActivity(user.uid, activityData);
        const newActivity = { ...activityData, id: activityId };
        setActivities(prev => [newActivity, ...prev]);
        return activityId;
      }
    } catch (error) {
      console.error('Error adding activity:', error);
      throw error;
    }
  };

  const updateActivity = async (activity: Activity) => {
    try {
      if (isGuest) {
        setActivities(prev => 
          prev.map(a => a.id === activity.id ? activity : a)
        );
      } else if (user) {
        await saveActivity(user.uid, activity);
        setActivities(prev =>
          prev.map(a => a.id === activity.id ? activity : a)
        );
      }
    } catch (error) {
      console.error('Error updating activity:', error);
      throw error;
    }
  };

  const deleteActivity = async (id: string) => {
    try {
      if (isGuest) {
        setActivities(prev => prev.filter(a => a.id !== id));
      } else if (user) {
        // Mark as deleted in Firestore
        await saveActivity(user.uid, { id, deleted: true } as Partial<Activity>);
        setActivities(prev => prev.filter(a => a.id !== id));
      }
    } catch (error) {
      console.error('Error deleting activity:', error);
      throw error;
    }
  };

  return { 
    activities, 
    loading, 
    error, 
    addActivity, 
    updateActivity, 
    deleteActivity 
  };
}