import { useState, useEffect } from 'react';
import { ActivitySuggestion } from '../services/openai';

const LIKED_KEY = 'likedSuggestions';
const REMOVED_KEY = 'removedSuggestions';

export function useSuggestionPreferences() {
  const [likedSuggestions, setLikedSuggestions] = useState<ActivitySuggestion[]>(() => {
    try {
      const stored = localStorage.getItem(LIKED_KEY);
      return stored ? JSON.parse(stored) : [];
    } catch (error) {
      console.error('Error loading liked suggestions:', error);
      return [];
    }
  });

  const [removedSuggestions, setRemovedSuggestions] = useState<ActivitySuggestion[]>(() => {
    try {
      const stored = localStorage.getItem(REMOVED_KEY);
      return stored ? JSON.parse(stored) : [];
    } catch (error) {
      console.error('Error loading removed suggestions:', error);
      return [];
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(LIKED_KEY, JSON.stringify(likedSuggestions));
    } catch (error) {
      console.error('Error saving liked suggestions:', error);
    }
  }, [likedSuggestions]);

  useEffect(() => {
    try {
      localStorage.setItem(REMOVED_KEY, JSON.stringify(removedSuggestions));
    } catch (error) {
      console.error('Error saving removed suggestions:', error);
    }
  }, [removedSuggestions]);

  const toggleLike = (suggestion: ActivitySuggestion) => {
    setLikedSuggestions(current => {
      const exists = current.some(s => s.name === suggestion.name);
      if (exists) {
        return current.filter(s => s.name !== suggestion.name);
      }
      return [...current, suggestion];
    });
  };

  const removeSuggestion = (suggestion: ActivitySuggestion) => {
    setRemovedSuggestions(current => [...current, suggestion]);
    setLikedSuggestions(current => 
      current.filter(s => s.name !== suggestion.name)
    );
  };

  const isLiked = (suggestion: ActivitySuggestion) => {
    return likedSuggestions.some(s => s.name === suggestion.name);
  };

  const clearAllSuggestions = () => {
    setLikedSuggestions([]);
    setRemovedSuggestions([]);
    localStorage.removeItem(LIKED_KEY);
    localStorage.removeItem(REMOVED_KEY);
  };

  return {
    likedSuggestions,
    removedSuggestions,
    toggleLike,
    removeSuggestion,
    isLiked,
    clearAllSuggestions
  };
}