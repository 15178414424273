import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCategories } from '../hooks/useCategories';
import { useActivityTypes } from '../hooks/useActivityTypes';
import { getActivityRecommendations, ActivitySuggestion } from '../services/openai';
import { useSuggestionPreferences } from '../hooks/useSuggestionPreferences';
import { useRateLimit } from '../hooks/useRateLimit';
import FindActivitiesForm from '../components/find-activities/FindActivitiesForm';
import SuggestionResults from '../components/find-activities/SuggestionResults';
import DebugSection from '../components/find-activities/DebugSection';
import Layout from '../components/Layout';

export default function FindActivities() {
  const navigate = useNavigate();
  const { categories } = useCategories();
  const { activityTypes } = useActivityTypes();
  const { toggleLike, removeSuggestion } = useSuggestionPreferences();
  const { checkRateLimit, incrementCount, isGuest } = useRateLimit();
  
  const [selectedCategory, setSelectedCategory] = useState<string>('n/a');
  const [selectedType, setSelectedType] = useState<string>('all');
  const [selectedChild, setSelectedChild] = useState<string>('n/a');
  const [age, setAge] = useState('');
  const [location, setLocation] = useState('');
  const [distance, setDistance] = useState('10');
  const [condition, setCondition] = useState('no-preference');
  const [duration, setDuration] = useState('60');
  const [maxCost, setMaxCost] = useState('50');
  const [considerations, setConsiderations] = useState('');
  const [suggestions, setSuggestions] = useState<ActivitySuggestion[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [lastPrompt, setLastPrompt] = useState<string | null>(null);

  const handleCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`${latitude}, ${longitude}`);
        },
        () => {
          setError('Unable to get current location. Please enter location manually.');
        }
      );
    } else {
      setError('Geolocation is not supported by your browser. Please enter location manually.');
    }
  };

  const handleSearch = async () => {
    if (!location.trim()) {
      setError('Please enter a location');
      return;
    }

    if (selectedChild === 'n/a' && !age) {
      setError('Please enter an age');
      return;
    }

    const { allowed, remaining } = checkRateLimit();
    if (!allowed) {
      setError(
        isGuest 
          ? 'Login or Create an account to generate more suggestions'
          : 'You have reached the maximum number of suggestions for this hour'
      );
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      let childAge: number | undefined;
      
      if (selectedChild !== 'n/a') {
        const child = categories.find(c => c.id === selectedChild);
        childAge = child ? parseInt(child.name.split(' ')[1]) : undefined;
      } else if (age) {
        childAge = parseInt(age);
      }

      const { suggestions: newSuggestions, prompt } = await getActivityRecommendations({
        activityType: selectedType,
        childAge,
        duration: parseInt(duration),
        condition,
        location: location.trim(),
        distance: parseInt(distance),
        maxCost: parseInt(maxCost),
        considerations: considerations.trim() || undefined
      });

      setLastPrompt(prompt);
      setSuggestions(newSuggestions);
      setCurrentPage(1);
      incrementCount();

      if (remaining - 1 > 0) {
        setError(`You have ${remaining - 1} suggestion attempts remaining for this hour`);
      }
    } catch (err: any) {
      if (err?.error?.code === 'insufficient_quota') {
        setError('Our activity suggestion service is currently unavailable. Please try again later or explore our preset activities.');
      } else {
        setError('Unable to fetch activity suggestions. Please try again later.');
      }
      console.error('Error getting recommendations:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLike = (suggestion: ActivitySuggestion) => {
    toggleLike(suggestion);
  };

  const handleRemove = (suggestion: ActivitySuggestion) => {
    setSuggestions(current => current.filter(s => s.name !== suggestion.name));
    removeSuggestion(suggestion);
  };

  const handleClearSuggestions = () => {
    setSuggestions([]);
    setCurrentPage(1);
    setError(null);
    setLastPrompt(null);
  };

  const handleAddActivity = (suggestion: ActivitySuggestion) => {
    navigate('/activity/new', { 
      state: { 
        suggestion,
        prefilledData: {
          name: suggestion.name,
          location: suggestion.location || '',
          duration: suggestion.duration,
          notes: suggestion.description
        }
      }
    });
  };

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="space-y-8">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">Find Activities</h2>
            
            <FindActivitiesForm
              categories={categories}
              activityTypes={activityTypes}
              selectedCategory={selectedCategory}
              selectedType={selectedType}
              selectedChild={selectedChild}
              age={age}
              location={location}
              distance={distance}
              condition={condition}
              duration={duration}
              maxCost={maxCost}
              considerations={considerations}
              isLoading={isLoading}
              onCategoryChange={setSelectedCategory}
              onTypeChange={setSelectedType}
              onChildChange={setSelectedChild}
              onAgeChange={setAge}
              onLocationChange={setLocation}
              onDistanceChange={setDistance}
              onConditionChange={setCondition}
              onDurationChange={setDuration}
              onMaxCostChange={setMaxCost}
              onConsiderationsChange={setConsiderations}
              onCurrentLocation={handleCurrentLocation}
              onSearch={handleSearch}
              onClear={handleClearSuggestions}
              showClearButton={suggestions.length > 0}
            />

            {error && (
              <div className="mt-6 bg-red-50 dark:bg-red-900 p-4 rounded-lg">
                <p className="text-sm text-red-600 dark:text-red-200">{error}</p>
              </div>
            )}
          </div>

          {suggestions.length > 0 && (
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <SuggestionResults
                suggestions={suggestions}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onLike={handleLike}
                onRemove={handleRemove}
                onAddActivity={handleAddActivity}
                onClear={handleClearSuggestions}
              />
            </div>
          )}

          {lastPrompt && (
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <DebugSection prompt={lastPrompt} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}