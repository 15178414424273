import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useCategories } from '../../hooks/useCategories';

export default function StandardActivitiesSettings() {
  const { categories, addCategory, removeCategory } = useCategories();
  const [newStandardCategory, setNewStandardCategory] = useState('');
  const [isAddingStandard, setIsAddingStandard] = useState(false);

  const standardCategories = categories.filter(cat => cat.type === 'standard');

  const handleAddCategory = () => {
    if (newStandardCategory.trim()) {
      addCategory(newStandardCategory.trim(), 'standard');
      setNewStandardCategory('');
    }
    setIsAddingStandard(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleAddCategory();
    } else if (e.key === 'Escape') {
      setNewStandardCategory('');
      setIsAddingStandard(false);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-3">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Standard Activities</h3>
        <button
          onClick={() => setIsAddingStandard(true)}
          className="p-1 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
        >
          <PlusIcon className="h-5 w-5" />
        </button>
      </div>
      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
        {standardCategories.map((category) => (
          <li key={category.id} className="py-3 flex justify-between items-center">
            <span className="text-gray-900 dark:text-gray-200">{category.name}</span>
            <button
              onClick={() => removeCategory(category.id)}
              className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
            >
              Remove
            </button>
          </li>
        ))}
        {isAddingStandard && (
          <li className="py-2">
            <input
              type="text"
              value={newStandardCategory}
              onChange={(e) => setNewStandardCategory(e.target.value)}
              onKeyDown={handleKeyPress}
              onBlur={handleAddCategory}
              placeholder="New activity name"
              autoFocus
              className="w-full rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </li>
        )}
      </ul>
    </div>
  );
}