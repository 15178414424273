import { useState } from 'react';
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import { ChevronDownIcon, ChevronUpIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ActivitySuggestion } from '../services/openai';

interface SuggestionCardProps {
  suggestion: ActivitySuggestion;
  isLiked: boolean;
  onLike: () => void;
  onRemove: () => void;
  onAddActivity: () => void;
}

export default function SuggestionCard({ 
  suggestion, 
  isLiked,
  onLike,
  onRemove,
  onAddActivity 
}: SuggestionCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleLike = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onLike();
  };

  const handleRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove();
  };

  return (
    <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden">
      <div className="p-4">
        {/* Card Header - Always visible */}
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            {suggestion.name}
          </h3>
          <div className="flex space-x-2">
            <button
              onClick={handleLike}
              className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-600"
              title={isLiked ? 'Unlike' : 'Like'}
              aria-label={isLiked ? 'Unlike activity' : 'Like activity'}
            >
              {isLiked ? (
                <HeartIconSolid className="h-5 w-5 text-red-500" />
              ) : (
                <HeartIcon className="h-5 w-5 text-gray-400 hover:text-red-500" />
              )}
            </button>
            <button
              onClick={handleRemove}
              className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-600"
              title="Remove"
              aria-label="Remove activity"
            >
              <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-red-500" />
            </button>
          </div>
        </div>

        {/* Basic Info - Always visible */}
        <div className="space-y-2 text-sm mb-4">
          <div className="flex justify-between">
            <span className="text-gray-500 dark:text-gray-400">Duration:</span>
            <span className="text-gray-900 dark:text-white">{suggestion.duration} min</span>
          </div>
          {suggestion.location && (
            <div className="flex justify-between">
              <span className="text-gray-500 dark:text-gray-400">Location:</span>
              <span className="text-gray-900 dark:text-white">{suggestion.location}</span>
            </div>
          )}
          <div className="flex justify-between">
            <span className="text-gray-500 dark:text-gray-400">Cost:</span>
            <span className="text-gray-900 dark:text-white">{suggestion.estimatedCost}</span>
          </div>
        </div>

        {/* Expand/Collapse Button */}
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="w-full flex items-center justify-center text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 mb-4"
          aria-expanded={isExpanded}
          aria-controls={`details-${suggestion.name}`}
        >
          {isExpanded ? (
            <>
              <ChevronUpIcon className="h-4 w-4 mr-1" />
              Show Less
            </>
          ) : (
            <>
              <ChevronDownIcon className="h-4 w-4 mr-1" />
              Show More
            </>
          )}
        </button>

        {/* Expanded Content */}
        {isExpanded && (
          <div 
            id={`details-${suggestion.name}`}
            className="pt-4 border-t border-gray-200 dark:border-gray-600 space-y-4"
          >
            <p className="text-gray-600 dark:text-gray-300">
              {suggestion.description}
            </p>
            
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <span className="text-gray-500 dark:text-gray-400">Type:</span>
                <span className="ml-2 text-gray-900 dark:text-white">{suggestion.type}</span>
              </div>
              
              <div>
                <span className="text-gray-500 dark:text-gray-400">Age Range:</span>
                <span className="ml-2 text-gray-900 dark:text-white">{suggestion.ageRange}</span>
              </div>
              
              <div>
                <span className="text-gray-500 dark:text-gray-400">Environment:</span>
                <span className="ml-2 text-gray-900 dark:text-white">{suggestion.indoorOutdoor}</span>
              </div>
            </div>

            {suggestion.materials.length > 0 && (
              <div>
                <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
                  Required Materials:
                </h4>
                <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-300">
                  {suggestion.materials.map((material, index) => (
                    <li key={index}>{material}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {/* Action Button */}
        <button
          onClick={onAddActivity}
          className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          aria-label="Add activity"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          Add Activity
        </button>
      </div>
    </div>
  );
}