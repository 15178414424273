import { useState, useEffect } from 'react';
import { auth, googleProvider } from '../config/firebase';
import { 
  User, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut,
  signInWithPopup,
  updateProfile,
  updatePassword,
  onAuthStateChanged
} from 'firebase/auth';
import { initializeUserData } from '../services/firebase';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [isGuest, setIsGuest] = useState(() => {
    return localStorage.getItem('isGuest') === 'true';
  });
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setIsGuest(false);
        localStorage.removeItem('isGuest');
        
        // Initialize user data in Firestore
        try {
          await initializeUserData(user.uid, user.email);
        } catch (error) {
          console.error('Error initializing user data:', error);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    // Auto-enable guest mode if no user is logged in
    if (!user && !isGuest) {
      continueAsGuest();
    }

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem('isGuest', isGuest.toString());
  }, [isGuest]);

  const login = async (email: string, password: string) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      setIsGuest(false);
      return result.user;
    } catch (error: any) {
      console.error('Login error:', error);
      throw new Error('Invalid email or password');
    }
  };

  const loginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setIsGuest(false);
      return result.user;
    } catch (error: any) {
      console.error('Google sign in error:', error);
      throw new Error('Failed to sign in with Google');
    }
  };

  const signup = async (email: string, password: string, profile: { name: string }) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(result.user, { displayName: profile.name });
      setIsGuest(false);
      return result.user;
    } catch (error: any) {
      console.error('Signup error:', error);
      throw new Error('Failed to create account');
    }
  };

  const updateUserProfile = async (profile: { displayName?: string; photoURL?: string }) => {
    if (!user) return;
    setIsLoading(true);
    try {
      await updateProfile(user, profile);
      setUser({ ...user, ...profile });
    } catch (error) {
      console.error('Update profile error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserPassword = async (newPassword: string) => {
    if (!user) return;
    setIsLoading(true);
    try {
      await updatePassword(user, newPassword);
    } catch (error) {
      console.error('Update password error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsGuest(false);
      continueAsGuest(); // Auto-enable guest mode after logout
    } catch (error) {
      console.error('Logout error:', error);
      throw new Error('Failed to sign out');
    }
  };

  const continueAsGuest = () => {
    setIsGuest(true);
    setUser(null);
    localStorage.setItem('isGuest', 'true');
  };

  return { 
    user, 
    isGuest, 
    loading,
    isLoading,
    login, 
    loginWithGoogle, 
    signup, 
    logout,
    continueAsGuest,
    updateUserProfile,
    updateUserPassword
  };
}