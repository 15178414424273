import { ActivitySuggestion } from '../../services/openai';
import SuggestionCard from '../SuggestionCard';
import { useSuggestionPreferences } from '../../hooks/useSuggestionPreferences';

interface SuggestionResultsProps {
  suggestions: ActivitySuggestion[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  onLike: (suggestion: ActivitySuggestion) => void;
  onRemove: (suggestion: ActivitySuggestion) => void;
  onAddActivity: (suggestion: ActivitySuggestion) => void;
  onClear: () => void;
}

const ITEMS_PER_PAGE = 6;

export default function SuggestionResults({
  suggestions,
  currentPage,
  setCurrentPage,
  onLike,
  onRemove,
  onAddActivity,
  onClear
}: SuggestionResultsProps) {
  const { isLiked } = useSuggestionPreferences();
  const totalPages = Math.ceil(suggestions.length / ITEMS_PER_PAGE);
  const paginatedSuggestions = suggestions.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Activity Suggestions
        </h2>
        <button
          onClick={onClear}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Clear Results
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {paginatedSuggestions.map((suggestion) => (
          <SuggestionCard
            key={suggestion.name}
            suggestion={suggestion}
            isLiked={isLiked(suggestion)}
            onLike={() => onLike(suggestion)}
            onRemove={() => onRemove(suggestion)}
            onAddActivity={() => onAddActivity(suggestion)}
          />
        ))}
      </div>

      {totalPages > 1 && (
        <div className="flex justify-center space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={`px-3 py-1 rounded-md ${
                currentPage === page
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}