import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useActivities from '../hooks/useActivities';
import { useActivityTypes } from '../hooks/useActivityTypes';
import { useCategories } from '../hooks/useCategories';
import { ACTIVITY_COLORS, Activity } from '../types/activity';
import { ActivitySuggestion } from '../services/openai';
import Layout from '../components/Layout';

interface LocationState {
  suggestion?: ActivitySuggestion;
  prefilledData?: {
    name: string;
    location: string;
    duration: number;
    notes: string;
  };
}

interface FormData {
  name: string;
  type: string;
  color: string;
  location: string;
  duration: number;
  children: string[];
  notes: string;
  date: string;
}

export default function ActivityForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { activities, addActivity, updateActivity } = useActivities();
  const { activityTypes } = useActivityTypes();
  const { categories } = useCategories();

  const locationState = location.state as LocationState;
  const prefilledData = locationState?.prefilledData;

  const standardActivities = categories
    .filter(cat => cat.type === 'standard')
    .map(cat => cat.name);

  const availableChildren = categories
    .filter(cat => cat.type === 'children')
    .map(cat => cat.name);

  const [formData, setFormData] = useState<FormData>({
    name: prefilledData?.name || '',
    type: activityTypes[0]?.id || '',
    color: ACTIVITY_COLORS[0].value,
    location: prefilledData?.location || '',
    duration: prefilledData?.duration || 60,
    children: [],
    notes: prefilledData?.notes || '',
    date: new Date().toISOString().split('T')[0]
  });

  useEffect(() => {
    if (id) {
      const activity = activities.find(a => a.id === id);
      if (activity) {
        setFormData({
          ...activity,
          date: new Date(activity.date).toISOString().split('T')[0],
          notes: activity.notes || ''
        });
      }
    }
  }, [id, activities]);

  return (
    <Layout>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
              {id ? 'Edit Activity' : 'New Activity'}
            </h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
              Fill in the details for your activity.
            </p>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={(e) => {
            e.preventDefault();
            const submitData = async () => {
              try {
                if (id) {
                  await updateActivity({
                    ...formData,
                    id,
                    date: new Date(formData.date),
                  } as Activity);
                } else {
                  await addActivity({
                    ...formData,
                    date: new Date(formData.date),
                  });
                }
                navigate('/activities');
              } catch (error) {
                console.error('Error saving activity:', error);
              }
            };
            submitData();
          }}>
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white dark:bg-gray-800 px-4 py-5 sm:p-6">
                {/* Activity Type */}
                <div>
                  <label htmlFor="type" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Activity Type
                  </label>
                  <select
                    id="type"
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  >
                    <option value="">Select a type</option>
                    {activityTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Activity Name */}
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Activity Name
                  </label>
                  <select
                    id="name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  >
                    <option value="">Select an activity</option>
                    {standardActivities.map((activity) => (
                      <option key={activity} value={activity}>
                        {activity}
                      </option>
                    ))}
                    <option value="custom">Custom Activity</option>
                  </select>

                  {formData.name === 'custom' && (
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      placeholder="Enter custom activity name"
                      className="mt-2 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                    />
                  )}
                </div>

                {/* Color Selection */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Color
                  </label>
                  <div className="mt-2 grid grid-cols-6 gap-2">
                    {ACTIVITY_COLORS.map((color) => (
                      <button
                        key={color.value}
                        type="button"
                        onClick={() => setFormData({ ...formData, color: color.value })}
                        className={`h-8 w-8 rounded-full ${
                          formData.color === color.value ? 'ring-2 ring-offset-2 ring-blue-500' : ''
                        }`}
                        style={{ backgroundColor: color.value }}
                      />
                    ))}
                  </div>
                </div>

                {/* Location */}
                <div>
                  <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Location
                  </label>
                  <input
                    type="text"
                    id="location"
                    value={formData.location}
                    onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>

                {/* Date */}
                <div>
                  <label htmlFor="date" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    value={formData.date}
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>

                {/* Duration */}
                <div>
                  <label htmlFor="duration" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Duration (minutes)
                  </label>
                  <input
                    type="number"
                    id="duration"
                    value={formData.duration}
                    onChange={(e) => setFormData({ ...formData, duration: parseInt(e.target.value) })}
                    min="1"
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>

                {/* Children */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Children
                  </label>
                  <div className="mt-2 space-y-2">
                    {availableChildren.map((child) => (
                      <label key={child} className="inline-flex items-center mr-4">
                        <input
                          type="checkbox"
                          checked={formData.children.includes(child)}
                          onChange={(e) => {
                            const updatedChildren = e.target.checked
                              ? [...formData.children, child]
                              : formData.children.filter((c) => c !== child);
                            setFormData({ ...formData, children: updatedChildren });
                          }}
                          className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">{child}</span>
                      </label>
                    ))}
                  </div>
                </div>

                {/* Notes */}
                <div>
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Notes
                  </label>
                  <textarea
                    id="notes"
                    value={formData.notes}
                    onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                    rows={3}
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>
              </div>

              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {id ? 'Update' : 'Create'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}