import { useState } from 'react';
import { useActivityTypes } from '../../hooks/useActivityTypes';
import { PlusIcon } from '@heroicons/react/24/outline';

export default function ActivityTypeSettings() {
  const { activityTypes, addActivityType, removeActivityType } = useActivityTypes();
  const [newType, setNewType] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const handleAdd = () => {
    if (newType.trim()) {
      addActivityType(newType.trim());
      setNewType('');
    }
    setIsAdding(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleAdd();
    } else if (e.key === 'Escape') {
      setNewType('');
      setIsAdding(false);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-3">
        <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
          Activity Types
        </h3>
        <button
          onClick={() => setIsAdding(true)}
          className="p-1 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
        >
          <PlusIcon className="h-5 w-5" />
        </button>
      </div>
      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
        {activityTypes.map((type) => (
          <li key={type.id} className="py-3 flex justify-between items-center">
            <span className="text-gray-900 dark:text-gray-200">{type.name}</span>
            <button
              onClick={() => removeActivityType(type.id)}
              className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
            >
              Remove
            </button>
          </li>
        ))}
        {isAdding && (
          <li className="py-2">
            <input
              type="text"
              value={newType}
              onChange={(e) => setNewType(e.target.value)}
              onKeyDown={handleKeyPress}
              onBlur={handleAdd}
              placeholder="New activity type"
              autoFocus
              className="w-full rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </li>
        )}
      </ul>
    </div>
  );
}