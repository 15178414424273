import { MapPinIcon } from '@heroicons/react/24/outline';
import { Category } from '../../types/category';
import { ActivityType } from '../../types/activityType';

interface FindActivitiesFormProps {
  categories: Category[];
  activityTypes: ActivityType[];
  selectedCategory: string;
  selectedType: string;
  selectedChild: string;
  age: string;
  location: string;
  distance: string;
  condition: string;
  duration: string;
  maxCost: string;
  considerations: string;
  isLoading: boolean;
  onCategoryChange: (value: string) => void;
  onTypeChange: (value: string) => void;
  onChildChange: (value: string) => void;
  onAgeChange: (value: string) => void;
  onLocationChange: (value: string) => void;
  onDistanceChange: (value: string) => void;
  onConditionChange: (value: string) => void;
  onDurationChange: (value: string) => void;
  onMaxCostChange: (value: string) => void;
  onConsiderationsChange: (value: string) => void;
  onCurrentLocation: () => void;
  onSearch: () => void;
  onClear: () => void;
  showClearButton: boolean;
}

const DISTANCE_OPTIONS = [
  { value: '5', label: '5 miles' },
  { value: '10', label: '10 miles' },
  { value: '15', label: '15 miles' },
  { value: '25', label: '25 miles' },
  { value: '50', label: '50 miles' },
  { value: '100', label: '100 miles' }
];

const CONDITIONS = [
  { value: 'indoor', label: 'Indoor' },
  { value: 'outdoor', label: 'Outdoor' },
  { value: 'no-preference', label: 'No Preference' }
];

export default function FindActivitiesForm({
  categories,
  activityTypes,
  selectedCategory,
  selectedType,
  selectedChild,
  age,
  location,
  distance,
  condition,
  duration,
  maxCost,
  considerations,
  isLoading,
  onCategoryChange,
  onTypeChange,
  onChildChange,
  onAgeChange,
  onLocationChange,
  onDistanceChange,
  onConditionChange,
  onDurationChange,
  onMaxCostChange,
  onConsiderationsChange,
  onCurrentLocation,
  onSearch,
  onClear,
  showClearButton
}: FindActivitiesFormProps) {
  const childrenCategories = categories.filter(cat => cat.type === 'children');

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="category" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Category
          </label>
          <select
            id="category"
            value={selectedCategory}
            onChange={(e) => onCategoryChange(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
          >
            <option value="all">All Categories</option>
            {categories
              .filter(cat => cat.type === 'standard')
              .map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))
            }
          </select>
        </div>

        <div>
          <label htmlFor="type" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Activity Type
          </label>
          <select
            id="type"
            value={selectedType}
            onChange={(e) => onTypeChange(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
          >
            <option value="all">All Types</option>
            {activityTypes.map(type => (
              <option key={type.id} value={type.name}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="child" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Child
          </label>
          <select
            id="child"
            value={selectedChild}
            onChange={(e) => onChildChange(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
          >
            <option value="n/a">N/A</option>
            {childrenCategories.map(child => (
              <option key={child.id} value={child.id}>
                {child.name}
              </option>
            ))}
          </select>
        </div>

        {selectedChild === 'n/a' && (
          <div>
            <label htmlFor="age" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Age
            </label>
            <input
              type="number"
              id="age"
              value={age}
              onChange={(e) => onAgeChange(e.target.value)}
              min="0"
              max="18"
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
              placeholder="Enter age"
            />
          </div>
        )}

        <div>
          <label htmlFor="duration" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Duration (minutes)
          </label>
          <input
            type="number"
            id="duration"
            value={duration}
            onChange={(e) => onDurationChange(e.target.value)}
            min="15"
            max="240"
            step="15"
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
          />
        </div>

        <div>
          <label htmlFor="maxCost" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Max Cost ($)
          </label>
          <input
            type="number"
            id="maxCost"
            value={maxCost}
            onChange={(e) => onMaxCostChange(e.target.value)}
            min="0"
            step="5"
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
          />
        </div>

        <div>
          <label htmlFor="condition" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Environment
          </label>
          <select
            id="condition"
            value={condition}
            onChange={(e) => onConditionChange(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
          >
            {CONDITIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Location
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              id="location"
              value={location}
              onChange={(e) => onLocationChange(e.target.value)}
              placeholder="Enter a location"
              className="block w-full rounded-md border-gray-300 dark:border-gray-600 pr-10 focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
              required
            />
            <button
              type="button"
              onClick={onCurrentLocation}
              className="absolute inset-y-0 right-0 px-3 flex items-center"
            >
              <MapPinIcon className="h-5 w-5 text-gray-400 hover:text-primary-500" />
            </button>
          </div>
        </div>

        <div>
          <label htmlFor="distance" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Distance
          </label>
          <select
            id="distance"
            value={distance}
            onChange={(e) => onDistanceChange(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
          >
            {DISTANCE_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="md:col-span-2">
          <label htmlFor="considerations" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Special Considerations
          </label>
          <textarea
            id="considerations"
            value={considerations}
            onChange={(e) => onConsiderationsChange(e.target.value)}
            placeholder="Any special needs, preferences, or requirements?"
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <button
          onClick={onSearch}
          disabled={isLoading}
          className="flex-1 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
        >
          {isLoading ? 'Searching...' : 'Find Activities'}
        </button>

        {showClearButton && (
          <button
            onClick={onClear}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Clear Results
          </button>
        )}
      </div>
    </div>
  );
}