import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';

const GUEST_LIMIT = 3;
const USER_LIMIT = 5;
const STORAGE_KEY = 'activitySuggestionCounts';

interface RateLimitData {
  count: number;
  timestamp: number;
}

export function useRateLimit() {
  const { isGuest } = useAuth();
  const [count, setCount] = useState(0);

  useEffect(() => {
    const loadCount = () => {
      const stored = localStorage.getItem(STORAGE_KEY);
      if (stored) {
        const data: RateLimitData = JSON.parse(stored);
        const hourAgo = Date.now() - 3600000;
        
        if (data.timestamp > hourAgo) {
          setCount(data.count);
        } else {
          setCount(0);
          localStorage.removeItem(STORAGE_KEY);
        }
      }
    };

    loadCount();
  }, []);

  const saveCount = (newCount: number) => {
    const data: RateLimitData = {
      count: newCount,
      timestamp: Date.now()
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  };

  const checkRateLimit = () => {
    const limit = isGuest ? GUEST_LIMIT : USER_LIMIT;
    const remaining = limit - count;
    return {
      allowed: count < limit,
      remaining
    };
  };

  const incrementCount = () => {
    const newCount = count + 1;
    setCount(newCount);
    saveCount(newCount);
  };

  return {
    checkRateLimit,
    incrementCount,
    isGuest
  };
}