import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { app } from './firebase-app';

// Initialize Firestore
export const db = getFirestore(app);

// Enable Firestore offline persistence
enableIndexedDbPersistence(db)
  .catch(error => {
    if (error.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence enabled in first tab only');
    } else if (error.code === 'unimplemented') {
      console.warn('Browser does not support persistence');
    }
  });