import { ActivitySuggestion } from '../services/openai';

export const sampleSuggestions: ActivitySuggestion[] = [
  {
    name: "Nature Scavenger Hunt",
    description: "An exciting outdoor adventure where kids search for specific items in nature like pinecones, interesting leaves, and colorful rocks.",
    location: "Local Park",
    duration: 60,
    type: "Outdoor Adventure",
    ageRange: "4-12 years",
    materials: ["Collection bags", "Scavenger hunt checklist", "Water bottles"],
    indoorOutdoor: "Outdoor",
    estimatedCost: "Free"
  },
  {
    name: "DIY Science Lab",
    description: "Create simple and safe science experiments using household items to teach basic scientific principles.",
    location: "Home",
    duration: 45,
    type: "Educational",
    ageRange: "5-10 years",
    materials: ["Baking soda", "Vinegar", "Food coloring", "Safety goggles"],
    indoorOutdoor: "Indoor",
    estimatedCost: "$10-15"
  },
  {
    name: "Mini Olympics",
    description: "Set up various athletic stations in the backyard for jumping, running, throwing, and balancing competitions.",
    location: "Backyard",
    duration: 90,
    type: "Sports",
    ageRange: "3-12 years",
    materials: ["Balls", "Jump rope", "Hula hoops", "Timer"],
    indoorOutdoor: "Outdoor",
    estimatedCost: "$20-30"
  },
  {
    name: "Art Gallery Creation",
    description: "Kids create various artworks using different mediums and then set up their own art gallery to showcase their masterpieces.",
    location: "Home",
    duration: 120,
    type: "Arts & Crafts",
    ageRange: "4-12 years",
    materials: ["Paper", "Paint", "Markers", "Display materials"],
    indoorOutdoor: "Indoor",
    estimatedCost: "$15-25"
  },
  {
    name: "Treasure Island Adventure",
    description: "Transform your space into a pirate-themed adventure with treasure maps, clues, and hidden rewards.",
    location: "Home/Backyard",
    duration: 60,
    type: "Adventure",
    ageRange: "5-10 years",
    materials: ["Treasure map", "Small prizes", "Pirate decorations"],
    indoorOutdoor: "Both",
    estimatedCost: "$25-35"
  }
];