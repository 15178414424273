import { useState } from 'react';
import Layout from '../components/Layout';
import ThemeSettings from '../components/settings/ThemeSettings';
import StandardActivitiesSettings from '../components/settings/StandardActivitiesSettings';
import ActivityTypeSettings from '../components/settings/ActivityTypeSettings';
import ChildrenSettings from '../components/settings/ChildrenSettings';
import UserProfileSettings from '../components/settings/UserProfileSettings';
import SuggestionSettings from '../components/settings/SuggestionSettings';
import { useAuth } from '../hooks/useAuth';

const sections = [
  { id: 'theme', name: 'Theme' },
  { id: 'activities', name: 'Standard Activities' },
  { id: 'activityTypes', name: 'Activity Types' },
  { id: 'children', name: 'Children' },
  { id: 'profile', name: 'User Profile', showWhenLoggedIn: true },
  { id: 'suggestions', name: 'Activity Suggestions' }
];

export default function Settings() {
  const [activeSection, setActiveSection] = useState('theme');
  const { user } = useAuth();

  const renderSection = () => {
    switch (activeSection) {
      case 'theme':
        return <ThemeSettings />;
      case 'activities':
        return <StandardActivitiesSettings />;
      case 'activityTypes':
        return <ActivityTypeSettings />;
      case 'children':
        return <ChildrenSettings />;
      case 'profile':
        return user ? <UserProfileSettings /> : null;
      case 'suggestions':
        return <SuggestionSettings />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="flex h-full">
          <div className="w-64 bg-white dark:bg-gray-800 shadow-sm rounded-lg">
            <nav className="p-4 space-y-1">
              {sections
                .filter(section => !section.showWhenLoggedIn || user)
                .map(section => (
                  <button
                    key={section.id}
                    onClick={() => setActiveSection(section.id)}
                    className={`w-full text-left px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                      activeSection === section.id
                        ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
                        : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
                    }`}
                  >
                    {section.name}
                  </button>
                ))}
            </nav>
          </div>

          <div className="flex-1 min-w-0 bg-white dark:bg-gray-800 ml-4 p-6 rounded-lg shadow">
            {renderSection()}
          </div>
        </div>
      </div>
    </Layout>
  );
}