import { Activity } from '../types/activity';

// Helper to get dates relative to today
const getRelativeDate = (dayOffset: number) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + dayOffset);
  return date;
};

export const sampleActivities: Activity[] = [
  {
    id: '1',
    name: 'Swimming Lessons',
    type: 'Sports',
    color: '#4A90E2',
    date: getRelativeDate(-2), // 2 days ago
    location: 'Community Pool',
    duration: 45,
    children: ['Dax', 'Hazel'],
    notes: 'Both kids are making great progress with their freestyle'
  },
  {
    id: '2',
    name: 'Soccer Practice',
    type: 'Sports',
    color: '#50E3C2',
    date: getRelativeDate(-1), // Yesterday
    location: 'Memorial Park',
    duration: 60,
    children: ['Dax'],
    notes: 'First time at soccer practice'
  },
  {
    id: '3',
    name: 'Art Class',
    type: 'Arts & Crafts',
    color: '#FF6B6B',
    date: getRelativeDate(0), // Today
    location: 'Creative Kids Studio',
    duration: 90,
    children: ['Hazel'],
    notes: 'Painted with watercolors today'
  }
];