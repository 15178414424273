import { Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../hooks/useAuth';
import Footer from './Footer';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Summary', href: '/summary' },
  { name: 'Calendar', href: '/activities' },
  { name: 'Find Activities', href: '/find-activities' },
  { name: 'Settings', href: '/settings' },
];

export default function Layout({ children }: { children: React.ReactNode }) {
  const { user, isGuest, logout, continueAsGuest } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleGuestMode = () => {
    continueAsGuest();
    navigate('/summary');
  };

  const isHomePage = location.pathname === '/';

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Disclosure as="nav" className={`${isHomePage ? 'bg-black' : 'bg-white dark:bg-gray-800'} shadow-sm`}>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <span className="text-xl font-bold text-primary-600 dark:text-primary-400">Activity Tracker</span>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${
                          location.pathname === item.href
                            ? 'text-primary-600 dark:text-primary-400'
                            : isHomePage 
                              ? 'text-white hover:text-primary-400'
                              : 'text-gray-900 dark:text-gray-100'
                        }`}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <Menu as="div" className="relative ml-3">
                    <Menu.Button className="flex rounded-full bg-white dark:bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                      {user?.photoURL ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.photoURL}
                          alt={user.displayName || 'User profile'}
                        />
                      ) : (
                        <UserCircleIcon className="h-8 w-8 text-gray-400" />
                      )}
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {user && (
                          <div className="px-4 py-2 text-sm text-gray-700 dark:text-gray-200 border-b border-gray-200 dark:border-gray-700">
                            <div className="font-medium">{user.displayName}</div>
                            <div className="text-gray-500 dark:text-gray-400 text-xs truncate">
                              {user.email}
                            </div>
                          </div>
                        )}
                        {user ? (
                          <>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/settings"
                                  className={`${
                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                  } block px-4 py-2 text-sm text-gray-700 dark:text-gray-200`}
                                >
                                  Settings
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={handleLogout}
                                  className={`${
                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                  } block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-200`}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </>
                        ) : isGuest ? (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={handleLogin}
                                className={`${
                                  active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                } block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-200`}
                              >
                                Sign in
                              </button>
                            )}
                          </Menu.Item>
                        ) : (
                          <>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={handleLogin}
                                  className={`${
                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                  } block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-200`}
                                >
                                  Sign in
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={handleGuestMode}
                                  className={`${
                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                  } block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-200`}
                                >
                                  Continue as guest
                                </button>
                              )}
                            </Menu.Item>
                          </>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white dark:bg-gray-800 p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-3 pt-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`block py-2 pl-3 pr-4 text-base font-medium ${
                      location.pathname === item.href
                        ? 'text-primary-600 dark:text-primary-400'
                        : 'text-gray-700 dark:text-gray-200'
                    }`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="border-t border-gray-200 dark:border-gray-700 pb-3 pt-4">
                {user && (
                  <div className="px-4 mb-2">
                    <div className="text-base font-medium text-gray-800 dark:text-gray-200">
                      {user.displayName}
                    </div>
                    <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      {user.email}
                    </div>
                  </div>
                )}
                <div className="space-y-1">
                  {user ? (
                    <>
                      <Link
                        to="/settings"
                        className="block px-4 py-2 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        Settings
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="block w-full px-4 py-2 text-left text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        Sign out
                      </button>
                    </>
                  ) : isGuest ? (
                    <button
                      onClick={handleLogin}
                      className="block w-full px-4 py-2 text-left text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      Sign in
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={handleLogin}
                        className="block w-full px-4 py-2 text-left text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        Sign in
                      </button>
                      <button
                        onClick={handleGuestMode}
                        className="block w-full px-4 py-2 text-left text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        Continue as guest
                      </button>
                    </>
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <main className={`${isHomePage ? 'bg-black' : ''}`}>
        {children}
      </main>
      
      <Footer />
    </div>
  );
}