import { useRateLimit } from '../../hooks/useRateLimit';
import { useSuggestionPreferences } from '../../hooks/useSuggestionPreferences';
import SuggestionCard from '../SuggestionCard';

export default function SuggestionSettings() {
  const { checkRateLimit, isGuest } = useRateLimit();
  const { likedSuggestions, removeSuggestion, toggleLike, isLiked } = useSuggestionPreferences();
  const { allowed, remaining } = checkRateLimit();

  return (
    <div>
      <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-6">Activity Suggestions</h2>
      
      <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg mb-6">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-sm font-medium text-blue-800 dark:text-blue-200">
              Suggestions Remaining: {remaining}
            </p>
            <p className="text-sm text-blue-600 dark:text-blue-300">
              {isGuest ? 'Create an account for more suggestions!' : 'More suggestions available soon!'}
            </p>
          </div>
          {!allowed && (
            <span className="text-xs text-blue-500 dark:text-blue-400">
              Purchase more suggestions coming soon!
            </span>
          )}
        </div>
      </div>

      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
        Liked Activity Suggestions
      </h3>
      <div className="space-y-6">
        {likedSuggestions.map((suggestion) => (
          <SuggestionCard
            key={suggestion.name}
            suggestion={suggestion}
            isLiked={isLiked(suggestion)}
            onLike={() => toggleLike(suggestion)}
            onRemove={() => removeSuggestion(suggestion)}
            onAddActivity={() => {}}
          />
        ))}
        {likedSuggestions.length === 0 && (
          <p className="text-gray-500 dark:text-gray-400 text-center">
            No liked suggestions yet. Like suggestions from the Find Activities page to see them here.
          </p>
        )}
      </div>
    </div>
  );
}