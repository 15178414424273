import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Activity } from '../types/activity';

type Granularity = 'day' | 'week' | 'month' | 'year';

interface CalendarProps {
  selectedDate: Date;
  onSelectDate: (date: Date) => void;
  activities: Activity[];
}

export default function Calendar({ selectedDate, onSelectDate, activities }: CalendarProps) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [granularity, setGranularity] = useState<Granularity>('month');

  const daysInMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0
  ).getDate();

  const firstDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1
  ).getDay();

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const previousMonthDays = Array.from({ length: firstDayOfMonth }, (_, i) => i);

  const getActivitiesForDate = (date: Date) => {
    return activities.filter(activity => {
      const activityDate = new Date(activity.date);
      switch (granularity) {
        case 'day':
          return (
            activityDate.getDate() === date.getDate() &&
            activityDate.getMonth() === date.getMonth() &&
            activityDate.getFullYear() === date.getFullYear()
          );
        case 'week':
          const weekStart = new Date(date);
          weekStart.setDate(date.getDate() - date.getDay());
          const weekEnd = new Date(weekStart);
          weekEnd.setDate(weekStart.getDate() + 6);
          return activityDate >= weekStart && activityDate <= weekEnd;
        case 'month':
          return (
            activityDate.getMonth() === date.getMonth() &&
            activityDate.getFullYear() === date.getFullYear()
          );
        case 'year':
          return activityDate.getFullYear() === date.getFullYear();
      }
    });
  };

  const previousPeriod = () => {
    switch (granularity) {
      case 'day':
        setCurrentMonth(new Date(currentMonth.setDate(currentMonth.getDate() - 1)));
        break;
      case 'week':
        setCurrentMonth(new Date(currentMonth.setDate(currentMonth.getDate() - 7)));
        break;
      case 'month':
        setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
        break;
      case 'year':
        setCurrentMonth(new Date(currentMonth.setFullYear(currentMonth.getFullYear() - 1)));
        break;
    }
  };

  const nextPeriod = () => {
    switch (granularity) {
      case 'day':
        setCurrentMonth(new Date(currentMonth.setDate(currentMonth.getDate() + 1)));
        break;
      case 'week':
        setCurrentMonth(new Date(currentMonth.setDate(currentMonth.getDate() + 7)));
        break;
      case 'month':
        setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
        break;
      case 'year':
        setCurrentMonth(new Date(currentMonth.setFullYear(currentMonth.getFullYear() + 1)));
        break;
    }
  };

  const formatPeriodLabel = () => {
    switch (granularity) {
      case 'day':
        return currentMonth.toLocaleDateString('default', { 
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      case 'week':
        const weekStart = new Date(currentMonth);
        weekStart.setDate(currentMonth.getDate() - currentMonth.getDay());
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        return `${weekStart.toLocaleDateString('default', { month: 'short', day: 'numeric' })} - ${weekEnd.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })}`;
      case 'month':
        return currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' });
      case 'year':
        return currentMonth.getFullYear().toString();
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
      <div className="flex items-center justify-between mb-4">
        <div className="flex space-x-2">
          {(['day', 'week', 'month', 'year'] as const).map((g) => (
            <button
              key={g}
              onClick={() => setGranularity(g)}
              className={`px-3 py-1 rounded-md text-sm ${
                granularity === g
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              {g.charAt(0).toUpperCase() + g.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-between mb-4">
        <button onClick={previousPeriod} className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full">
          <ChevronLeftIcon className="h-5 w-5 text-gray-600 dark:text-gray-400" />
        </button>
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
          {formatPeriodLabel()}
        </h2>
        <button onClick={nextPeriod} className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full">
          <ChevronRightIcon className="h-5 w-5 text-gray-600 dark:text-gray-400" />
        </button>
      </div>

      {granularity === 'month' && (
        <>
          <div className="grid grid-cols-7 gap-1 mb-2">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <div key={day} className="text-center text-sm font-medium text-gray-500 dark:text-gray-400">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 gap-1">
            {previousMonthDays.map(day => (
              <div key={`prev-${day}`} className="h-10 text-center text-gray-400 dark:text-gray-600">
                {' '}
              </div>
            ))}
            {days.map(day => {
              const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
              const dayActivities = getActivitiesForDate(date);
              const isSelected = selectedDate.getDate() === day && 
                               selectedDate.getMonth() === currentMonth.getMonth() &&
                               selectedDate.getFullYear() === currentMonth.getFullYear();

              return (
                <button
                  key={day}
                  onClick={() => onSelectDate(date)}
                  className={`h-10 relative flex items-center justify-center rounded-full
                    ${isSelected ? 'bg-blue-600 text-white' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}
                    ${dayActivities.length > 0 ? 'font-semibold' : ''}
                  `}
                >
                  {day}
                  {dayActivities.length > 0 && (
                    <span className="absolute bottom-1 h-1 w-1 rounded-full bg-blue-500"></span>
                  )}
                </button>
              );
            })}
          </div>
        </>
      )}

      {granularity === 'week' && (
        <div className="grid grid-cols-7 gap-1">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => {
            const date = new Date(currentMonth);
            date.setDate(date.getDate() - date.getDay() + index);
            const dayActivities = getActivitiesForDate(date);
            const isSelected = selectedDate.getDate() === date.getDate() && 
                             selectedDate.getMonth() === date.getMonth() &&
                             selectedDate.getFullYear() === date.getFullYear();

            return (
              <div key={day} className="space-y-1">
                <div className="text-center text-sm font-medium text-gray-500 dark:text-gray-400">
                  {day}
                </div>
                <button
                  onClick={() => onSelectDate(date)}
                  className={`w-full h-20 relative flex flex-col items-center justify-center rounded-lg
                    ${isSelected ? 'bg-blue-600 text-white' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}
                  `}
                >
                  <span className="text-lg">{date.getDate()}</span>
                  {dayActivities.length > 0 && (
                    <span className="text-sm text-blue-500">{dayActivities.length} activities</span>
                  )}
                </button>
              </div>
            );
          })}
        </div>
      )}

      {granularity === 'year' && (
        <div className="grid grid-cols-4 gap-4">
          {Array.from({ length: 12 }, (_, i) => {
            const date = new Date(currentMonth.getFullYear(), i, 1);
            const monthActivities = getActivitiesForDate(date);
            const isSelected = selectedDate.getMonth() === i && 
                             selectedDate.getFullYear() === currentMonth.getFullYear();

            return (
              <button
                key={i}
                onClick={() => {
                  setGranularity('month');
                  setCurrentMonth(date);
                }}
                className={`h-24 relative flex flex-col items-center justify-center rounded-lg
                  ${isSelected ? 'bg-blue-600 text-white' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}
                `}
              >
                <span className="text-lg">
                  {date.toLocaleString('default', { month: 'short' })}
                </span>
                {monthActivities.length > 0 && (
                  <span className="text-sm text-blue-500">{monthActivities.length} activities</span>
                )}
              </button>
            );
          })}
        </div>
      )}

      {granularity === 'day' && (
        <div className="space-y-4">
          {getActivitiesForDate(currentMonth).map((activity) => (
            <div
              key={activity.id}
              className="p-4 rounded-lg bg-gray-50 dark:bg-gray-700 flex items-center space-x-4"
            >
              <div
                className="h-4 w-4 rounded-full"
                style={{ backgroundColor: activity.color }}
              />
              <div className="flex-1">
                <h3 className="font-medium text-gray-900 dark:text-white">{activity.name}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{activity.location}</p>
              </div>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {activity.duration} min
              </span>
            </div>
          ))}
          {getActivitiesForDate(currentMonth).length === 0 && (
            <p className="text-center text-gray-500 dark:text-gray-400">
              No activities for this day
            </p>
          )}
        </div>
      )}
    </div>
  );
}