import OpenAI from 'openai';
import { sampleSuggestions } from '../data/sampleSuggestions';

const openai = import.meta.env.VITE_OPENAI_API_KEY ? new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
}) : null;

export interface ActivitySuggestion {
  name: string;
  description: string;
  location?: string;
  latitude?: number;
  longitude?: number;
  duration: number;
  type: string;
  ageRange: string;
  materials: string[];
  indoorOutdoor: string;
  estimatedCost: string;
}

export interface ActivityRecommendationRequest {
  activityType: string;
  childAge?: number;
  duration: number;
  condition: string;
  location?: string;
  distance?: number;
  maxCost?: number;
  considerations?: string;
}

interface CachedResponse {
  suggestions: ActivitySuggestion[];
  prompt: string;
  timestamp: number;
}

const cache = new Map<string, CachedResponse>();
const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes

export async function getActivityRecommendations(
  request: ActivityRecommendationRequest
): Promise<{ suggestions: ActivitySuggestion[]; prompt: string }> {
  try {
    // If OpenAI is not configured, return sample suggestions
    if (!openai) {
      return {
        suggestions: sampleSuggestions.slice(0, 5),
        prompt: "Using sample suggestions (OpenAI API key not configured)"
      };
    }

    const prompt = generatePrompt(request);
    const cacheKey = JSON.stringify(request);
    const cachedResult = cache.get(cacheKey);

    if (cachedResult && Date.now() - cachedResult.timestamp < CACHE_DURATION) {
      return {
        suggestions: cachedResult.suggestions.slice(0, 5),
        prompt: cachedResult.prompt
      };
    }

    const response = await openai.chat.completions.create({
      model: 'gpt-4-turbo-preview',
      messages: [
        {
          role: 'system',
          content: 'You are an expert children\'s activity planner with deep knowledge of child development, educational activities, and recreational opportunities. Provide concise, specific suggestions.'
        },
        {
          role: 'user',
          content: prompt
        }
      ],
      temperature: 0.7,
      max_tokens: 1000,
      presence_penalty: -0.5,
      frequency_penalty: 0.3
    });

    const content = response.choices[0]?.message?.content;
    if (!content) {
      throw new Error('No suggestions received');
    }

    const suggestions = await parseResponse(content, request.location);
    
    cache.set(cacheKey, {
      suggestions,
      prompt,
      timestamp: Date.now()
    });

    return { 
      suggestions: suggestions.slice(0, 5),
      prompt 
    };
  } catch (error: any) {
    console.warn('Error getting activity recommendations:', error);
    // Fallback to sample suggestions on error
    return {
      suggestions: sampleSuggestions.slice(0, 5),
      prompt: "Using sample suggestions (API error occurred)"
    };
  }
}

function generatePrompt(request: ActivityRecommendationRequest): string {
  return `Suggest 5 specific activities for children with these requirements:
${request.activityType !== 'all' ? `Type: ${request.activityType}` : ''}
${request.childAge ? `Age: ${request.childAge} years` : ''}
Duration: ${request.duration} minutes
Environment: ${request.condition === 'no-preference' ? 'Any' : request.condition}
${request.location ? `Location: Near ${request.location}` : ''}
${request.distance ? `Range: ${request.distance} miles` : ''}
${request.maxCost ? `Budget: $${request.maxCost}` : ''}
${request.considerations ? `Special needs: ${request.considerations}` : ''}

For each activity provide:
- Short, creative name
- Brief description (2-3 sentences)
- Specific location (if applicable)
- Duration in minutes
- Activity type
- Age range
- Required materials (comma-separated)
- Indoor/outdoor designation
- Cost estimate

Focus on practical, engaging activities.`;
}

async function parseResponse(content: string, location?: string): Promise<ActivitySuggestion[]> {
  const activities = content.split(/\d+\.\s+/).filter(Boolean);
  
  const suggestions = activities.map(activity => {
    const lines = activity.split('\n').filter(Boolean);
    const name = lines[0].trim();
    const description = lines.find(line => !line.includes(':'))?.trim() || '';
    
    const getValue = (key: string): string => {
      const line = lines.find(l => l.toLowerCase().includes(key.toLowerCase()));
      return line ? line.split(':')[1]?.trim() || '' : '';
    };

    const materials = getValue('materials')
      .split(',')
      .map(m => m.trim())
      .filter(Boolean);

    const activityLocation = getValue('location');

    return {
      name,
      description,
      location: activityLocation,
      duration: parseInt(getValue('duration')) || 60,
      type: getValue('type'),
      ageRange: getValue('age range'),
      materials,
      indoorOutdoor: getValue('indoor/outdoor'),
      estimatedCost: getValue('cost')
    };
  });

  // If a location was provided and Google Maps API is configured, geocode the locations
  if (location && import.meta.env.VITE_GOOGLE_MAPS_API_KEY && suggestions.length > 0) {
    const geocodedSuggestions = await Promise.all(
      suggestions.map(async (suggestion) => {
        if (suggestion.location) {
          try {
            const coords = await geocodeLocation(suggestion.location);
            return { ...suggestion, ...coords };
          } catch (error) {
            console.error('Error geocoding location:', error);
            return suggestion;
          }
        }
        return suggestion;
      })
    );
    return geocodedSuggestions;
  }

  return suggestions;
}

async function geocodeLocation(address: string): Promise<{ latitude?: number; longitude?: number }> {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();
    
    if (data.results && data.results[0]?.geometry?.location) {
      const { lat, lng } = data.results[0].geometry.location;
      return { latitude: lat, longitude: lng };
    }
  } catch (error) {
    console.error('Geocoding error:', error);
  }
  return {};
}