import { getAuth, GoogleAuthProvider, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { app } from './firebase-app';

// Initialize Firebase Auth
export const auth = getAuth(app);

// Configure auth persistence
setPersistence(auth, browserLocalPersistence)
  .catch(error => {
    console.warn('Auth persistence configuration failed:', error);
  });

// Configure Google Auth Provider
export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account'
});