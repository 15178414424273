import { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Activity } from '../types/activity';

interface ActivityBarChartProps {
  activities: Activity[];
  selectedChild: string;
  timeRange: 'week' | 'month' | 'year';
}

interface ChartData {
  name: string;
  duration: number;
  count: number;
  color: string;
}

export default function ActivityBarChart({ activities, selectedChild, timeRange }: ActivityBarChartProps) {
  const chartData = useMemo(() => {
    const now = new Date();
    now.setHours(23, 59, 59, 999); // End of current day
    
    const filteredActivities = activities.filter(activity => {
      const activityDate = new Date(activity.date);
      let isInTimeRange = false;

      switch (timeRange) {
        case 'week':
          const weekAgo = new Date(now);
          weekAgo.setDate(now.getDate() - 7);
          weekAgo.setHours(0, 0, 0, 0);
          isInTimeRange = activityDate >= weekAgo && activityDate <= now;
          break;
        case 'month':
          const monthAgo = new Date(now);
          monthAgo.setMonth(now.getMonth() - 1);
          monthAgo.setHours(0, 0, 0, 0);
          isInTimeRange = activityDate >= monthAgo && activityDate <= now;
          break;
        case 'year':
          const yearAgo = new Date(now);
          yearAgo.setFullYear(now.getFullYear() - 1);
          yearAgo.setHours(0, 0, 0, 0);
          isInTimeRange = activityDate >= yearAgo && activityDate <= now;
          break;
      }

      if (selectedChild !== 'all') {
        return isInTimeRange && activity.children.includes(selectedChild);
      }
      return isInTimeRange;
    });

    const activityStats = filteredActivities.reduce((acc: Record<string, ChartData>, activity) => {
      if (!acc[activity.name]) {
        acc[activity.name] = {
          name: activity.name,
          duration: 0,
          count: 0,
          color: activity.color
        };
      }
      acc[activity.name].duration += activity.duration;
      acc[activity.name].count += 1;
      return acc;
    }, {});

    return Object.values(activityStats)
      .sort((a, b) => b.duration - a.duration)
      .slice(0, 10); // Top 10 activities
  }, [activities, selectedChild, timeRange]);

  if (chartData.length === 0) {
    return (
      <div className="text-center text-gray-500 dark:text-gray-400 py-8">
        No activities to display
      </div>
    );
  }

  return (
    <div className="w-full h-96">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 60
          }}
        >
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            height={60}
            interval={0}
            stroke="currentColor"
            className="text-gray-600 dark:text-gray-400"
          />
          <YAxis
            label={{ 
              value: 'Duration (minutes)', 
              angle: -90, 
              position: 'insideLeft',
              className: 'text-gray-600 dark:text-gray-400'
            }}
            stroke="currentColor"
            className="text-gray-600 dark:text-gray-400"
          />
          <Tooltip
            contentStyle={{
              backgroundColor: 'var(--tw-bg-opacity)',
              border: 'none',
              borderRadius: '0.375rem',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
            }}
            formatter={(value: any, name: string, entry: any) => [
              `${value} minutes (${entry.payload.count} activities)`,
              name
            ]}
          />
          <Bar
            dataKey="duration"
            fill="#4A90E2"
            radius={[4, 4, 0, 0]}
            shape={(props: any) => {
              const { x, y, width, height, fill } = props;
              const index = props.index;
              const color = chartData[index]?.color || fill;
              return (
                <path
                  d={`M${x},${y + height}
                     L${x},${y + 4}
                     Q${x},${y} ${x + 4},${y}
                     L${x + width - 4},${y}
                     Q${x + width},${y} ${x + width},${y + 4}
                     L${x + width},${y + height}
                     Z`}
                  fill={color}
                />
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}