import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { getUserSettings, saveUserSettings } from '../services/firebase';

export interface Category {
  id: string;
  name: string;
  type: 'standard' | 'children';
}

const defaultCategories: Category[] = [
  { id: '1', name: 'Park Visit', type: 'standard' },
  { id: '2', name: 'Sports', type: 'standard' },
  { id: '3', name: 'Educational', type: 'standard' },
  { id: '4', name: 'Dax (4)', type: 'children' },
  { id: '5', name: 'Hazel (2)', type: 'children' }
];

export function useCategories() {
  const { user, isGuest } = useAuth();
  const [categories, setCategories] = useState<Category[]>(() => {
    if (isGuest) {
      const saved = localStorage.getItem('categories');
      return saved ? JSON.parse(saved) : defaultCategories;
    }
    return defaultCategories;
  });

  // Load categories from Firestore for authenticated users
  useEffect(() => {
    const loadCategories = async () => {
      if (!isGuest && user) {
        try {
          const settings = await getUserSettings(user.uid);
          if (settings?.categories) {
            setCategories(settings.categories);
          }
        } catch (error) {
          console.error('Error loading categories:', error);
        }
      }
    };

    loadCategories();
  }, [user, isGuest]);

  // Save categories
  useEffect(() => {
    if (isGuest) {
      localStorage.setItem('categories', JSON.stringify(categories));
    } else if (user) {
      saveUserSettings(user.uid, {
        categories,
        activityTypes: [], // Will be updated by useActivityTypes
        theme: 'dark' // Will be updated by useTheme
      }).catch(error => {
        console.error('Error saving categories:', error);
      });
    }
  }, [categories, user, isGuest]);

  const addCategory = (name: string, type: 'standard' | 'children') => {
    const newCategory: Category = {
      id: Math.random().toString(36).substring(2),
      name: name.trim(),
      type
    };
    setCategories(prev => [...prev, newCategory]);
  };

  const removeCategory = (id: string) => {
    setCategories(prev => prev.filter(cat => cat.id !== id));
  };

  return { categories, addCategory, removeCategory };
}