import { Link } from 'react-router-dom';
import { Activity } from '../types/activity';

interface ActivityListProps {
  activities: Activity[];
  showDate?: boolean;
}

export default function ActivityList({ activities, showDate = false }: ActivityListProps) {
  return (
    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
      {activities.map((activity) => (
        <li key={activity.id} className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className="h-4 w-4 rounded-full mr-3"
                style={{ backgroundColor: activity.color }}
              />
              <div>
                <p className="text-sm font-medium text-gray-900 dark:text-white">{activity.name}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">{activity.location}</p>
                {showDate && (
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {new Date(activity.date).toLocaleDateString('default', {
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric'
                    })}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {activity.duration} minutes
              </span>
              <Link
                to={`/activity/${activity.id}`}
                className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
              >
                Edit
              </Link>
            </div>
          </div>
        </li>
      ))}
      {activities.length === 0 && (
        <li className="px-4 py-8 text-center text-gray-500 dark:text-gray-400">
          No activities to display
        </li>
      )}
    </ul>
  );
}