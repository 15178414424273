import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useCategories } from '../../hooks/useCategories';

interface ChildInput {
  name: string;
  age: string;
}

export default function ChildrenSettings() {
  const { categories, addCategory, removeCategory } = useCategories();
  const [childInput, setChildInput] = useState<ChildInput>({ name: '', age: '' });
  const [isAddingChild, setIsAddingChild] = useState(false);
  const [error, setError] = useState('');

  const childrenCategories = categories.filter(cat => cat.type === 'children');

  const handleAddChild = () => {
    if (childInput.name.trim() && childInput.age.trim()) {
      const age = parseInt(childInput.age);
      if (isNaN(age) || age < 0 || age > 18) {
        setError('Please enter a valid age between 0 and 18');
        return;
      }
      addCategory(`${childInput.name.trim()} (${age})`, 'children');
      setChildInput({ name: '', age: '' });
      setError('');
    }
    setIsAddingChild(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-3">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Children</h3>
        <button
          onClick={() => setIsAddingChild(true)}
          className="p-1 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
        >
          <PlusIcon className="h-5 w-5" />
        </button>
      </div>
      {error && (
        <div className="mb-3 text-sm text-red-600 dark:text-red-400">{error}</div>
      )}
      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
        {childrenCategories.map((category) => (
          <li key={category.id} className="py-3 flex justify-between items-center">
            <span className="text-gray-900 dark:text-gray-200">{category.name}</span>
            <button
              onClick={() => removeCategory(category.id)}
              className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
            >
              Remove
            </button>
          </li>
        ))}
        {isAddingChild && (
          <li className="py-2 space-y-2">
            <input
              type="text"
              value={childInput.name}
              onChange={(e) => setChildInput({ ...childInput, name: e.target.value })}
              placeholder="Child's name"
              autoFocus
              className="w-full rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <div className="flex space-x-2">
              <input
                type="number"
                value={childInput.age}
                onChange={(e) => setChildInput({ ...childInput, age: e.target.value })}
                placeholder="Age"
                min="0"
                max="18"
                className="w-24 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              <button
                onClick={handleAddChild}
                className="flex-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Add
              </button>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}