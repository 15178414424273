interface Activity {
  id: string;
  name: string;
  type: string;
  color: string;
  date: Date;
  location: string;
  duration: number;
  children: string[];
  notes?: string;
  deleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

const ACTIVITY_COLORS = [
  { name: 'Sky Blue', value: '#4A90E2' },
  { name: 'Coral', value: '#FF6B6B' },
  { name: 'Mint', value: '#50E3C2' },
  { name: 'Lavender', value: '#A389F4' },
  { name: 'Sunshine', value: '#FFC82C' },
  { name: 'Rose', value: '#FF8AAE' }
] as const;

export type { Activity };
export { ACTIVITY_COLORS };