import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import {
  CalendarIcon,
  MapPinIcon,
  ChartBarIcon,
  UserGroupIcon,
  CloudIcon,
  LightBulbIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Activity Calendar',
    description: 'Track and organize activities with an intuitive calendar interface',
    icon: CalendarIcon
  },
  {
    name: 'Location-Based Activities',
    description: 'Discover activities near you with location-based recommendations',
    icon: MapPinIcon
  },
  {
    name: 'Detailed Analytics',
    description: 'Get insights into activity patterns and engagement',
    icon: ChartBarIcon
  },
  {
    name: 'Multi-Child Support',
    description: 'Manage activities for multiple children with ease',
    icon: UserGroupIcon
  },
  {
    name: 'Smart Suggestions',
    description: 'Receive personalized activity recommendations based on preferences',
    icon: LightBulbIcon
  },
  {
    name: 'Cloud Sync',
    description: 'Access your activities from anywhere with cloud synchronization',
    icon: CloudIcon
  }
];

export default function Home() {
  return (
    <Layout>
      <div className="relative isolate bg-black">
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <div className="mt-24 sm:mt-32 lg:mt-16">
              <a href="#features" className="inline-flex space-x-6">
                <span className="rounded-full bg-primary-500/10 px-3 py-1 text-sm font-semibold leading-6 text-primary-400 ring-1 ring-inset ring-primary-500/20">
                  Latest Update
                </span>
                <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                  <span>New activity suggestions</span>
                </span>
              </a>
            </div>
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Track and discover activities
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Keep everyone engaged and entertained with our comprehensive activity tracking and recommendation platform.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to="/summary"
                className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 inline-flex items-center"
              >
                Try it now
                <ArrowRightIcon className="ml-2 h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div id="features" className="bg-black">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-base font-semibold leading-7 text-primary-400">Everything you need</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Comprehensive Activity Management
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Our platform provides all the tools you need to plan, track, and discover activities.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-white">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-primary-600">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  );
}