import { useAuth } from '../hooks/useAuth';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { user, isGuest, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-8 h-8 border-2 border-blue-600 rounded-full animate-spin border-t-transparent"></div>
      </div>
    );
  }

  // Allow access if user is authenticated or in guest mode
  if (user || isGuest) {
    return <>{children}</>;
  }

  // Auto-enable guest mode instead of redirecting to login
  return <>{children}</>;
}