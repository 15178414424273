import { useTheme } from '../../hooks/useTheme';

export default function ThemeSettings() {
  const { theme, setTheme } = useTheme();

  return (
    <div>
      <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-6">Theme Settings</h2>
      <div className="space-y-4">
        {(['light', 'dark', 'system'] as const).map((option) => (
          <div key={option} className="flex items-center">
            <input
              id={option}
              name="theme"
              type="radio"
              checked={theme === option}
              onChange={() => setTheme(option)}
              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label
              htmlFor={option}
              className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-200"
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}